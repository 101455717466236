export const PAY_KEY = 'toktv-pay'; //用户下单时的订单ID
export const IPINFO_KEY = 'ip-info'; //用户的IP信息
export const SHAREDRAMAID_KEY = 'toktv-share-dramaid'; //用户通过分享打开的落地页首次存储的drama_id
export const START_TRIAL_KEY = '_fbq_StartTrial_report_';
export const COMPLETE_REGISTRATION_TRIAL_KEY = '_fbq_CompleteRegistration_report_';
export const K_PAY_ITEM = '__TOKTV_PAY_ITEM__';
export const K_CZ = '__TOKTV_CZ__';
export const K_PIXEL = '__TOKTV_PIXEL_ID__';
export const K_PLAT = '__TOKTV_PLAT__';
export const K_TABBAR_HEIGHT = '__TOKTV_TABBAR_HEIGHT__';
export const K_FIRST_SEARCH_PARAMS = '__TOKTV_FIRST_SEARCH_PARAMS__';
export const K_CUR_EPISODE_MAP = '__TOKTV_CUR_EPISODE_MAP__';

export const MY_EXTRA_INFO = 'my_extra_info';
export const K_LOG_DRAMA_ID = 'log_drama_id';
export const K_DRAMA_ID = 'drama_id';
export const GUIDE_FLAG = '_TOKTV_GUIDE_FLAG_';
export const TOKTV_MOVIETASK_ID = '_TOKTV_MOVIETASK_ID_';
export const TOKTV_LANG = '_TOKTV_LANG_';
export const TOKTV_BACK_RETAIN = '_TOKTV_BACK_RETAIN_';
export const TOKTV_WEB_PUSH = '_TOKTV_WEB_PUSH_';
export const TOKTV_SHOW_SUBSCRIPTION_TIME = '_TOKTV_SHOW_SUBSCRIPTION_TIME_';
export const TOKTV_PAY_ORDERID = '_TOKTV_PAY_ORDERID_';
export const TOKTV_DRAMA_NUM_FLAG = '_TOKTV_DRAMA_NUM_FLAG_';
export const TOKTV_PAYHOLD_STOREDDATE = '_TOKTV_PAYHOLD_STOREDDATE_';
export const TOKTV_PAYHOLD_MAP = '_TOKTV_PAYHOLD_MAP_';
export const TOKTV_SW_REGISTERED = '_TOKTV_SW_REGISTERED_';
export const TOKTV_K_GOODS_COUNT_DOWN_TIME = '_TOKTV_K_GOODS_COUNT_DOWN_TIME_';
