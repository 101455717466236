//web端活动日志上报
import { type IHwDomain, XLog } from '@xyz-fe/log/web-hw';
import { getCachedIPInfo, getPixelId, getPlat } from '@/utils/auth';
import { getPlatform, getQueryParam } from '.';
import { LoginInfo } from '@/pages/index/store/modules/user';

const testQid = ['_log_', '_log1_', '_log2_', '_log3_', '_log4_'];
const isPro = import.meta.env.VITE_APP_ENV === 'production';
const APP_APPVER = import.meta.env.VITE_APP_APPVER;
const APP_APPTYPEID = import.meta.env.VITE_APP_APPTYPEID;
const qid = getQueryParam('qid');
const pix = getQueryParam('pix') || getPixelId() || '3693950444204942';
const plat = getQueryParam('plat') || getPlat() || 'fb';
const cachedLoginInfo = useStorage<LoginInfo>('TOKTV_STORE_USER', {});

const xl = new XLog({
  isPro,
  consoleLog: !isPro || testQid.includes(qid),
  commonParams: {
    appver: APP_APPVER,
    apptypeid: APP_APPTYPEID,
    appcqid: qid,
    accid: cachedLoginInfo.value?.accid || '',
    region: getCachedIPInfo()?.country || '',
    province: getCachedIPInfo()?.subdivision || '',
    platform: getPlatform(),
    userinfo: JSON.stringify({ pix, plat }),
  },
  ignoreUrlParams: ['my_extra_info'],
});

//默认渠道
export const isAppcqidOrganic = () => xl.commonParams.appcqid === 'organic';

//针对特定渠道不展示福利页面
export const isSpecialChannel = () =>
  ['fbff001', 'fbff003', 'fbff006'].includes(xl.commonParams.appcqid!);

export const isBrChannel = () =>
  [
    'fbff086',
    'fbff087',
    'fbff057',
    'fbff058',
    'fbff001',
    'fbff002',
    'fbff055',
    'fbff103',
    'fbff051',
    'fbff062',
  ].includes(xl.commonParams.appcqid!);

export const ignorePurchaseReturn = [
  'fbff059',
  'fbff072',
  'fbff073',
  'fbff074',
  'fbff075',
  'fbff063',
  'fbff064',
  'fbff076',
  'fbff077',
  'fbff078',
  'fbff079',
  'fbff065',
  'fbff066',
  'fbff067',
  'fbff068',
  'fbff069',
].includes(xl.commonParams.appcqid!);

export default xl;
