export enum LocaleLangEnum {
  zhCN = 'zh-CN',
  zhHK = 'zh-HK',
  viVN = 'vi-VN',
  thTH = 'th-TH',
  idID = 'id-ID',
  enUS = 'en-US',
  ptBR = 'pt-BR',
  esEs = 'es-ES',
}

/**
 * 接口语言数据类型
 */
type RemoteLangType = 'cn' | 'cnt' | 'en' | 'th' | 'vi' | 'id' | 'pt' | 'es';

export const supportedUrlLang = ['cn', 'cnt', 'en', 'th', 'pt', 'es'];

/**
 * 将i18n中的数据转成接口要的数据
 * @param {string} locale
 * @returns {string}
 */
export const locale2lang = (locale: string): RemoteLangType => {
  switch (locale) {
    case LocaleLangEnum.zhCN:
      return 'cn';
    case LocaleLangEnum.zhHK:
      return 'cnt';
    case LocaleLangEnum.viVN:
      return 'vi';
    case LocaleLangEnum.thTH:
      return 'th';
    case LocaleLangEnum.idID:
      return 'id';
    case LocaleLangEnum.enUS:
      return 'en';
    case LocaleLangEnum.ptBR:
      return 'pt';
    case LocaleLangEnum.esEs:
      return 'es';
    default:
      return 'en';
  }
};

/**
 * 浏览器语言转成i18n中的数据
 * @param {string} lang
 * @returns {string} LocaleLangEnum
 */
export const lang2locale = (lang: string): LocaleLangEnum => {
  const newLang = lang.substring(0, 2);
  const oldLang = lang.substring(lang.length - 2);
  console.log('oldLang:', oldLang);
  console.log('newLang:', newLang);
  switch (newLang) {
    case 'zh':
      return oldLang == 'CN' ? LocaleLangEnum.zhCN : LocaleLangEnum.zhHK;
    case 'vi':
      return LocaleLangEnum.viVN;
    case 'th':
      return LocaleLangEnum.thTH;
    case 'id':
      return LocaleLangEnum.idID;
    case 'en':
      return LocaleLangEnum.enUS;
    case 'pt':
      return LocaleLangEnum.ptBR;
    case 'es':
      return LocaleLangEnum.esEs;
    default:
      return LocaleLangEnum.enUS;
  }
};

/**
 * 服务端语言字符串转成i18n中的语言字符串
 * @param {string} lang
 * @returns {string} LocaleLangEnum
 */
export const server2locale = (lang: string): LocaleLangEnum => {
  switch (lang) {
    case 'cn':
      return LocaleLangEnum.zhCN;
    case 'cnt':
      return LocaleLangEnum.zhHK;
    case 'vi':
      return LocaleLangEnum.viVN;
    case 'th':
      return LocaleLangEnum.thTH;
    case 'id':
      return LocaleLangEnum.idID;
    case 'en':
      return LocaleLangEnum.enUS;
    case 'pt':
      return LocaleLangEnum.ptBR;
    case 'es':
      return LocaleLangEnum.esEs;
    default:
      return LocaleLangEnum.enUS;
  }
};
