import { MY_EXTRA_INFO, TOKTV_LANG } from '@/pages/index/keys';
import { isAndroid, isChromeOrSamsung, isMeta, isSafari } from './is';
import { LocaleLangEnum, lang2locale, supportedUrlLang } from '../locale/lang';
import { server2locale } from '@/locale/lang';
import CryptoJS from 'crypto-js';
// import platform from 'platform';
// console.log({ platform });

// export const isChrome = () => platform.name?.includes('Chrome');
// export const isSamsung = () => platform.name?.includes('Samsung');
// export const isChromeOrSamsung = isChrome() || isSamsung();

/**
 * 获取指定范围内的随机整数
 */
export const randomNumberInRange = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min) + min);
};

/**
 * sleep
 */
export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export function myPlatform() {
  const ua = navigator.userAgent.toLowerCase();
  let isWXWork = false;
  if (ua.match(/wxwork/i)) {
    isWXWork = true;
  }
  let isWeixin = false;
  if (!isWXWork && ua.match(/MicroMessenger/i)) {
    isWeixin = true;
  }
  let isMobile = false;
  let isDesktop = false;
  if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
    isMobile = true;
  } else {
    isDesktop = true;
  }

  return { isWeixin, isMobile, isDesktop };
}

/**
 * 版本号比较
 * @param v1 版本号v1
 * @param v2 版本号v2
 * @returns 1 v1 > v2; -1 v1 < v2; 0 v1 = v2
 */
export function compareVersion(v1: string, v2: string): 1 | -1 | 0 {
  const v1Arr = v1.split('.');
  const v2Arr = v2.split('.');
  const len = Math.max(v1Arr.length, v2Arr.length);

  while (v1Arr.length < len) {
    v1Arr.push('0');
  }
  while (v2Arr.length < len) {
    v2Arr.push('0');
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1Arr[i]);
    const num2 = parseInt(v2Arr[i]);

    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
}

export function getQueryParam(paramName: string) {
  let url = document.location.toString();
  // 如果url中有特殊字符则需要进行一下解码
  url = decodeURI(url);
  const arrObj = url.split('?');
  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split('&');
    let arr;
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=');
      if (arr != null && arr[0] == paramName) {
        return decodeURIComponent(arr[1]);
      }
    }
  }
  return '';
}

export function wakeUpWithIframe(url: string) {
  try {
    let ifr = document.getElementById('toktv_iframe') as HTMLIFrameElement;
    if (!ifr) {
      ifr = document.createElement('iframe');
      ifr.id = 'toktv_iframe';
      ifr.style.width = '0';
      ifr.style.height = '0';
      ifr.style.opacity = '0';
      ifr.name = 'toktv_iframe';
      document.body.appendChild(ifr);
    }
    ifr.src = url;
  } catch (e) {
    console.error(e);
  }
}

/**
 * 销毁iframe，释放iframe所占用的内存
 * @param iframe 须要销毁的iframe id
 */
export function destroyIframe(iframeID = 'toktv_iframe') {
  try {
    const iframe = document.getElementById(iframeID) as HTMLIFrameElement;
    //把iframe指向空白页面，这样能够释放大部份内存。
    document.body.removeChild(iframe);
  } catch (e) {
    console.error(e);
  }
}

export function wakeUpInIos(url: string) {
  try {
    wakeUpWithIframe(`googlechrome://${url.replace(/(https|http):\/\//, '')}`);
  } catch (e) {
    console.error(e);
  }
}

export function wakeUpInAndroid(url: string) {
  try {
    wakeUpWithIframe(
      `intent://${url.replace(
        /(https|http):\/\//,
        ''
      )}#Intent;scheme=https;action=android.intent.action.VIEW;component=com.android.chrome;package=com.android.chrome;end`
    );
  } catch (e) {
    console.error(e);
  }
}

export function wakeUp(url: string) {
  if (isAndroid) {
    wakeUpInAndroid(url);
  } else {
    wakeUpInIos(url);
  }
}

export function wakeUpWhenNotInChrome() {
  if (!isChromeOrSamsung && isAndroid) {
    wakeUp(location.href);
  }
}

/**
 * 获取当前页面的URL参数
 */
export function getRequest() {
  const theRequest: { [key: string]: string } = {};
  const urlSearch = window.location.search;
  if (urlSearch.indexOf('?') !== -1) {
    const str = urlSearch.substr(1);
    const strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      if (theRequest[strs[i].split('=')[0]] == null) {
        theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
      }
    }
  }
  return theRequest;
}
/**
 * 获取公参的network
 */
export function getNetWork() {
  const network = sessionStorage.getItem('network');
  const obj = {
    '15zsj3qy': 'h5_tiktok',
  };
  const myMap = new Map(Object.entries(obj));
  const res = myMap.get(`${network}`) || false;
  return res;
}

/**
 * 对象key和value以&符号拼接形成字符串
 */
export function splitVal(obj: { [key: string]: string }) {
  let strs = '';
  for (const str in obj) {
    strs += str + '=' + obj[str] + '&';
  }
  strs = strs.substring(0, strs.length - 1);
  return strs;
}

export function formatNumber(num: number): string {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k';
  }
  return num.toString();
}

// Determine the current display mode.
// export function isPWA() {
//   // 判断是否在 iOS 的全屏模式（PWA）中打开
//   if (navigator.standalone) {
//     return true;
//   }

//   // 判断是否在 Android 的全屏模式（PWA）中打开
//   if (window.matchMedia('(display-mode: standalone)').matches) {
//     return true;
//   }

//   // 默认返回 false，表示网页在浏览器中打开
//   return false;
// }

export function urlParamsToJson(url: string): Record<string, any> {
  const params = new URL(url).searchParams;
  const json: Record<string, any> = {};

  params.forEach((value, key) => {
    if (json[key]) {
      if (!Array.isArray(json[key])) {
        json[key] = [json[key]];
      }
      json[key].push(value);
    } else {
      json[key] = value;
    }
  });

  return json;
}

export function jsonToUrlParams(json: Record<string, any>): string {
  const params = new URLSearchParams();

  Object.entries(json).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        params.append(key, val);
      });
    } else {
      params.append(key, value);
    }
  });

  return params.toString();
}

// 合并两个URLSearchParams对象，使已有参数直接覆盖
export function mergeSearchParams(
  params1: URLSearchParams,
  params2: URLSearchParams
): URLSearchParams {
  const mergedParams = new URLSearchParams(params1);

  params2.forEach((value, key) => {
    // if (mergedParams.has(key)) {
    //   const existingValue = mergedParams.get(key);
    //   if (existingValue) {
    //     mergedParams.set(key, [existingValue, value].join(','));
    //   }
    // } else {
    //   mergedParams.append(key, value);
    // }
    mergedParams.set(key, value);
  });

  return mergedParams;
}

export const animateCSS = (
  element: string | HTMLElement,
  animation: string,
  prefix = 'animate__'
) =>
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = typeof element === 'string' ? document.querySelector(element) : element;
    if (node) {
      node.classList.add(`${prefix}animated`, animationName);

      // 动画结束，清除动画类，并resolve promise
      const handleAnimationEnd = (event: any) => {
        event.stopPropagation();
        node.classList.remove(`${prefix}animated`, animationName);
        resolve('Animation ended');
      };
      node.addEventListener('animationend', handleAnimationEnd, { once: true });
    } else {
      // 未找到DOM
      reject('element is not exist');
    }
  });

//日志打点（底部安装APP弹框日志打点）
export const installPopSubactid = () => {
  return isChromeOrSamsung ? '1' : isMeta ? '2' : isSafari ? '3' : '4';
};

// export function launchFullscreen(element: Element) {
//   if (element.requestFullscreen) {
//     return element.requestFullscreen();
//   } else if (element.mozRequestFullScreen) {
//     return element.mozRequestFullScreen();
//   } else if (element.webkitRequestFullscreen) {
//     return element.webkitRequestFullscreen();
//   } else if (element.msRequestFullscreen) {
//     return element.msRequestFullscreen();
//   }
// }

// export function isFullScreen() {
//   return (
//     document.fullscreenElement ||
//     document.mozFullScreenElement ||
//     document.webkitFullscreenElement ||
//     document.msFullscreenElement
//   );
// }

// function lockOrientation() {
//   if (window.screen.orientation) {
//     if (
//       window.screen.orientation.type === 'landscape-primary' ||
//       window.screen.orientation.type === 'landscape-secondary'
//     ) {
//       screen.orientation.lock('portrait');
//     }
//   } else if (window.screen.mozOrientation) {
//     if (
//       window.screen.mozOrientation === 'landscape-primary' ||
//       window.screen.mozOrientation === 'landscape-secondary'
//     ) {
//       screen.mozLockOrientation('portrait');
//     }
//   } else if (window.orientation) {
//     if (window.orientation === 90 || window.orientation === -90) {
//       window.addEventListener('orientationchange', function () {
//         if (window.orientation === 0) {
//           window.screen.lockOrientation('portrait-primary');
//         }
//       });
//     }
//   }
// }

export function getHrefWithoutMyExtraInfo(url = location.href) {
  const u = new URL(url);
  u.searchParams.delete(MY_EXTRA_INFO);
  return u.href;
}

export const isPwa = () => {
  const isPwa = getQueryParam('utm_medium') === 'PWA';
  // Determine the current display mode.
  const getDisplayMode = () =>
    (navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches
      ? 'standalone'
      : 'browser';
  return isPwa || getDisplayMode() === 'standalone';
};

export function isMobileDevice() {
  const userAgent = navigator.userAgent;
  const mobileKeywords = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone',
  ];

  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
}

export const getPlatform = () => (isPwa() ? 'pwa' : isMobileDevice() ? 'h5' : 'pc');

// 随机数（时间吹+三位随机数）
export function generateUniqueId() {
  const timestamp = Date.now(); // 当前时间戳
  const randomNum = Math.floor(100 + Math.random() * 900); // 生成100到999之间的随机数
  return `${timestamp}${randomNum}`;
}

// 随机数（时间吹+三位随机数+计数器）
// let counter = 0;
// function generateUniqueId() {
//   const timestamp = Date.now(); // 当前时间戳
//   const randomNum = Math.floor(100 + Math.random() * 900); // 生成100到999之间的随机数
//   counter += 1; // 计数器递增
//   return `${timestamp}${randomNum}${counter}`;
// }

export function getCurrentLang(): LocaleLangEnum {
  // 'cn' => '简中', 'cnt' => '繁中', 'en' => '英文', 'th' => '泰文',
  // 'vi' => '越南', 'id' => '印尼', 'pt' => '葡语'
  const urlLang = getQueryParam('lang');
  let lang = LocaleLangEnum.enUS;
  if (urlLang && supportedUrlLang.includes(urlLang)) {
    lang = server2locale(urlLang);
  } else {
    lang =
      (localStorage.getItem(TOKTV_LANG) as LocaleLangEnum | undefined) ||
      (lang2locale(navigator.language) as LocaleLangEnum);
  }
  localStorage.setItem(TOKTV_LANG, lang);
  console.log(lang, ' <<< lang');
  return lang;
}

export function encrypt(s: string, secret = 'toktv-2024') {
  return CryptoJS.AES.encrypt(s, secret).toString();
}

export function decrypt(cipherText: string, secret = 'toktv-2024') {
  const bytes = CryptoJS.AES.decrypt(cipherText, secret);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}
