import {
  COMPLETE_REGISTRATION_TRIAL_KEY,
  IPINFO_KEY,
  K_CUR_EPISODE_MAP,
  K_CZ,
  K_FIRST_SEARCH_PARAMS,
  K_PAY_ITEM,
  K_PIXEL,
  K_PLAT,
  K_TABBAR_HEIGHT,
  SHAREDRAMAID_KEY,
  START_TRIAL_KEY,
  TOKTV_BACK_RETAIN,
  TOKTV_SHOW_SUBSCRIPTION_TIME,
  TOKTV_PAYHOLD_MAP,
  TOKTV_WEB_PUSH,
  TOKTV_SW_REGISTERED,
} from '@/pages/index/keys/index';
import { getQueryParam } from '.';
import dayjs from 'dayjs';

const pix = getQueryParam('pix') || getPixelId() || '3693950444204942';
const plat = getQueryParam('plat') || getPlat() || 'fb';

// cz
export function getCz() {
  return localStorage.getItem(K_CZ);
}

export function setCz(val: string) {
  localStorage.setItem(K_CZ, val);
}

export function clearCz() {
  localStorage.removeItem(K_CZ);
}

// pix
export function getPixelId() {
  return localStorage.getItem(K_PIXEL);
}

export function setPixelId(val: string) {
  localStorage.setItem(K_PIXEL, val);
}

export function clearPixelId() {
  localStorage.removeItem(K_PIXEL);
}

// plat
export function getPlat() {
  return localStorage.getItem(K_PLAT);
}

export function setPlat(val: string) {
  localStorage.setItem(K_PLAT, val);
}

export function clearPlat() {
  localStorage.removeItem(K_PLAT);
}

export function getLocalStorage(key: string) {
  return localStorage.getItem(key);
}

export function setLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function clearLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function getCachedIPInfo() {
  const info = localStorage.getItem(IPINFO_KEY);
  if (info) return JSON.parse(info);
  return null;
}

export function cacheIPInfo(ipInfo: Record<string | number, any>) {
  localStorage.setItem(IPINFO_KEY, JSON.stringify(ipInfo));
}

export function clearIPInfo() {
  localStorage.removeItem(IPINFO_KEY);
}

export function clearLocalStorageAll() {
  localStorage.clear();
}

export function clearsessionStorageAll() {
  sessionStorage.clear();
}

export function getShareDramaId() {
  return sessionStorage.getItem(SHAREDRAMAID_KEY);
}

export function setShareDramaId(id: string) {
  if (!sessionStorage.getItem(SHAREDRAMAID_KEY)) {
    sessionStorage.setItem(SHAREDRAMAID_KEY, id);
  }
}

// 支付
export const payItem = useStorage<any>(K_PAY_ITEM, '');

// 安装启动的回传标记（保证只回传一次）
export const startTrialFlag = useStorage(`${START_TRIAL_KEY}_${plat}`, false);
// 登录成功的回传标记（保证只回传一次）
export const completeRegistrationFlag = useStorage(
  `${COMPLETE_REGISTRATION_TRIAL_KEY}_${plat}`,
  false
);

// 缓存首次打开带过来的url参数（广告URL参数）
export function setLaunchSearchParams(params: string) {
  const flagKey = `${K_FIRST_SEARCH_PARAMS}_FLAG`;
  if (!sessionStorage.getItem(flagKey) && params) {
    sessionStorage.setItem(K_FIRST_SEARCH_PARAMS, params);
    sessionStorage.setItem(flagKey, '1');
  }
}

export function getLaunchSearchParams() {
  return sessionStorage.getItem(K_FIRST_SEARCH_PARAMS);
}

export const tabBarHeight = useStorage(K_TABBAR_HEIGHT, 0);

export function clearCurEpisodeMap() {
  localStorage.removeItem(K_CUR_EPISODE_MAP);
}

export function clearPayHoldMap() {
  localStorage.removeItem(TOKTV_PAYHOLD_MAP);
}

export function logoutClearStorage() {
  clearPayHoldMap();
}

export const curEpisodeMap = useStorage<Recordable>(K_CUR_EPISODE_MAP, {});

export const swRegistered = useSessionStorage(TOKTV_SW_REGISTERED, false);

// 猜你喜欢标记
export const backRetain = useStorage(TOKTV_BACK_RETAIN, true, sessionStorage);
export const webPushFlag = useStorage(TOKTV_WEB_PUSH, true, sessionStorage);

export const showWebPushSubscriptionTime = useStorage(
  TOKTV_SHOW_SUBSCRIPTION_TIME,
  0,
  localStorage
);

export const shouldShowWebPushSubscription = () => {
  // 两次通知订阅弹窗时间间隔需保持在12h以上。
  const latestTime = dayjs(showWebPushSubscriptionTime.value).add(12, 'h');
  console.log('latestTime::', latestTime.format('YYYY-MM-DD HH:mm:ss'));
  return dayjs().isAfter(latestTime, 'h');
};
