import { UAParser } from 'ua-parser-js';
const parser = new UAParser();
const { os, browser } = parser.getResult();

export const isAndroid = os.name?.toLowerCase().includes('android');
export const isIOS = os.name?.toLowerCase() == 'ios';

export const isSafari = browser.name?.toLowerCase().includes('safari');
export const isChrome = browser.name?.toLowerCase().includes('chrome');
export const isSamsungBrowser = browser.name?.toLowerCase().includes('samsung');
export const isChromeOrSamsung = isChrome || isSamsungBrowser;

export const isFacebookBrowser = browser.name?.toLowerCase().includes('facebook');
export const isInstagramBrowser = browser.name?.toLowerCase().includes('instagram');
export const isMeta = isFacebookBrowser || isInstagramBrowser;

console.log('当前环境 :>> ', import.meta.env.MODE);
export const isPro = import.meta.env.MODE === 'production';

const { toString } = Object.prototype;

export function is(val: unknown, type: string): boolean {
  return toString.call(val) === `[object ${type}]`;
}

export function isString(val: any): val is string {
  return is(val, 'String');
}

export function isNumber(val: any): val is number {
  return is(val, 'Number');
}

export function isBoolean(val: any): val is boolean {
  return is(val, 'Boolean');
}

export function isObject(val: any): val is Record<string, any> {
  return val !== null && is(val, 'Object');
}

export function isEmptyObject(val: any): val is boolean {
  return isObject(val) && Object.keys(val).length === 0;
}

export function isArray(val: any): val is any[] {
  return val && Array.isArray(val);
}

export function isNull(val: any): val is null {
  return is(val, 'Null');
}

export function isUndefined(val: any): val is undefined {
  return is(val, 'Undefined');
}

export function isFunction(val: any): val is (...args: any[]) => any {
  return typeof val === 'function';
}

export function isFile(val: any): val is File {
  return is(val, 'File');
}

export function isBlob(val: any): val is Blob {
  return is(val, 'Blob');
}

export function isRegExp(val: any) {
  return is(val, 'RegExp');
}

export function isExternal(val: any) {
  return /^(https?:|mailto:|tel:)/.test(val);
}

export function isWIFI() {
  const navSet: any = window.navigator;
  return navSet.connection.effectiveType === 'wifi';
}
